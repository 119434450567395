module.exports = {
  jobs: [
    {
      company: "nameless",
      begin: {
        month: 'april',
        year: '2021'
      },
      duration: '14 months',
      occupation: "Automated / Trading",
      description: "\
      <p>\
      Discrete trading, algotrading, risk management, charting, pattern recognition, market psychology, personal psychology. \
      Automated trading, collecting trade data from exchanges, resampling, realtime processing. Chasing rabbits. Quite a journey.\
    </p>\
    <h4>Disciplines e.g</h4>\
    <ul>\
      <li>Python / Pandas</li>\
      <li>Data storage</li>\
      <li>Big data storage / Presto / Avro </li>\
      <li>Java / Scala / Nodejs / React / GraphQL / timescaledb</li>\
      <li>Stream / Reactive programming with Akka</li>\
      <li>Kubernetes / Rancher</li>\
      <li>A.I. / Machine learning</li>\
    </ul>\
    "
    },
    {
      company: "Conversation24",
      begin: {
        month: 'feb',
        year: '2020'
      },
      duration: '14 months',
      occupation: "SRE / devops / project manager",
      description: "\
        <p>\
          Conversation24 creates multi-channel messaging software. It was the responsiblity of me and my team to support developers working on the product, deploying, testing etc. \
          Once deployed, keep everything running within golden signal specification. \
          <br/><br/>\
          Infrastructure as code. Automated build of microservices ran as container images (docker) in Kubernetes at AWS or VMware. Some technologies used: Terraform, Packer, security compliance with openpolicyagent, checks using tfsec. The developers mostly wrote code using node / php using mongodb and message queues etc.\
          GitOps\
          Automated security compliance checks\
        </p>\
        <h4>Disciplines e.g</h4>\
        <ul>\
          <li>Management DTAP street</li>\
          <li>Proactive prevention and resolution of incidents</li>\
          <li>Keeping software and libraries up to date</li>\
          <li>Leading team as project manager and being responsible for continuation of service</li>\
        </ul>\
        "
    },
    {
      company: "Nameless",
      begin: {
        month: 'sep',
        year: '2018'
      },
      duration: '17 months',
      occupation: "algo / trading",
      description: "\
        <p>\
          More progress\
        </p>\
        <h4>Disciplines e.g</h4>\
        <ul>\
          <li>See <a href='#experience'>above</a></li>\
        </ul>\
        "
    }, {
      company: "ViaICT",
      begin: {
        month: 'dec',
        year: '2016'
      },
      duration: '4 years',
      occupation: "Site reliablity",
      description: "<p>\
      Development and maintenance of web applications and infrastructure involving a high traffic web-shop // \
      Leading development of a single page app webshop to scale rather than the old technology, setting up cloud computing \
      </p>\
        <h4>Disciplines e.g</h4>\
        <ul>\
          <li>Agile software development / Kanban / Continuous deployment</li>\
          <li>DC/OS / Terraform / Chef</li>\
          <li>ICT and crypto related work</li>\
          <li>Nodejs / React / GraphQL</li>\
        </ul>\
      "
    }, {
      company: "Travelling, independent jobs",
      begin: {
        month: 'jan',
        year: '2013'
      },
      duration: '3 yrs',
      occupation: "Traveling and odd jobs",
      description: "<p>Doing a variety of things</p>"
    }, {
      company: "Catawiki",
      begin: {
        month: 'jan',
        year: '2011'
      },
      duration: '2 years',
      occupation: "Full stack developer",
      description: "<p>With just three colleagues I was honoured to be at the beginning days of <a href='https://www.catawiki.com'>Catawiki</a> </p>\
      <h4>Disciplines e.g</h4>\
      <ul>\
        <li>Agile software development</li>\
        <li>Ruby on Rails, test driven</li>\
        <li>MySQL / Redis / Apache Solr</li>\
        <li>Automated the server deployement using Chef</li>\
      </ul>\
      "
    },
    {
      company: "Kozy Media",
      begin: {
        month: 'jan',
        year: '2008'
      },
      duration: '3 years',
      occupation: "Webhosting / Website development",
      description: "\
      <p>\
        As a long time Linux user, I had been developing websites in PHP and TYPO3 for a while but \
        Ruby on Rails was the framework that really started resonating with me, sparking a carreer programming.\
        <a href=\"https://kozy.nl\">Is this website still up?</a> I am afraid so\
      </p>\
      <h4>Disciplines e.g</h4>\
      <ul>\
      <li>Linux systems engineering</li>\
      <li>Ruby on Rails</li>\
      <li>TYPO3</li>\
      <li>Linux / MySQL / Apache / Nginx / etc</li>\
      <li>User support</li>\
      </ul>\
      "
    },
    {
      company: "Odd jobs",
      begin: {
        month: 'jan',
        year: '2005'
      },
      duration: '3 years',
      occupation: "",
      description: "<p></p>"
    },
    {
      company: "HBO opleiding",
      begin: {
        month: 'jan',
        year: '2002'
      },
      duration: '2 years',
      occupation: "Communication and Multimedia Design",
      description: "<p></p>"
    },
    {
      company: "Leek",
      begin: {
        month: 'sep',
        year: '1982'
      },
      duration: '37',
      occupation: "born",
      description: ""
    },
  ]
}
