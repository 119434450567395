import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb04 from '../assets/images/thumbs/04.jpg'
import thumb05 from '../assets/images/thumbs/05.jpg'
import thumb06 from '../assets/images/thumbs/06.jpg'
import thumb07 from '../assets/images/thumbs/07.jpg'
import thumb08 from '../assets/images/thumbs/08.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full04 from '../assets/images/fulls/04.jpg'
import full05 from '../assets/images/fulls/05.jpg'
import full06 from '../assets/images/fulls/06.jpg'
import full07 from '../assets/images/fulls/07.jpg'
import full08 from '../assets/images/fulls/08.jpg'

import Timeline from '../components/timeline'

const DEFAULT_IMAGES = [
    { id: '8', source: full08, thumbnail: thumb08, caption: 'Conversation24', description: 'Devops lead'},
    { id: '2', source: full01, thumbnail: thumb01, caption: 'Trade development and management', description: 'Develop trade plans, document and watch performance'},
    { id: '1', source: full02, thumbnail: thumb02, caption: 'Algotrading', description: 'Research and predict market behavior using Python, Pandas, streaming'},
    { id: '3', source: full04, thumbnail: thumb04, caption: 'Kubernetes cluster', description: 'From development to production. Git triggerd deploys, Linkerd, Prometheus, Envoy, EdgeFS '},
    { id: '5', source: full03, thumbnail: thumb03, caption: 'Rosefield watches', description: 'Successful high revenue retailer where meeting service levels is essential'},
    // { id: '6', source: full06, thumbnail: thumb06, caption: 'Photo 6', description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'},
    { id: '6', source: full07, thumbnail: thumb07, caption: 'Liberland', description: 'Worked on different platforms to help organise Liberland. <a href="https://github.com/liberland/site">Example codebase</a>. Via AWS services and others'},
    { id: '4', source: full05, thumbnail: thumb05, caption: 'ViaICT', description: 'Various projects for software developer, ICT and Webhoster ViaICT'},
];

class HomeIndex extends React.Component {

    render() {
        const siteTitle = "Koos Beens - Curriculum vitae"
        const siteDescription = "Create wonderful things"

        return (
            <Layout>
                <Helmet>
                <title>{siteTitle}</title>
                <meta name="description" content={siteDescription} />
                </Helmet>

                <div id="main">

                    <section id="one">
                        <header className="major">
                            <h2>Devops / Site reliabilty engineering</h2>
                        </header>

                        <p>
                          Lives in Haren, the Netherlands, with his cat, likes travel, enjoys nature, staying fit, healty eating.
                          Highly enjoys setting up resilient infrastructures so you can move fast while maintaining reliabilty.
                          Sometimes speaks in third person for more interesting reads.
                        </p>

                        <p>
                          I have been working on a variety of new and existing products and services, setting up infrastructure, paved ways to make the development experience a smooth ride,
                          automating were you can, monitoring along the way. Continuous delivery pipelines, failovers, backups, incident resolution etc.
                        </p>

                    </section>
                    <section id="two">
                        <h2>Recent Work</h2>
                        <Gallery images={DEFAULT_IMAGES.map(({ id, source, thumbnail, caption, description }) => ({
                            source,
                            thumbnail,
                            caption,
                            description
                        }))} />

                    </section>
                    <section>
                    <Timeline />
                    </section>


                    <section>
                    <p>
                      <em>Koos heeft een combinatie van skills die je niet vaak bij ontwikkelaars tegenkomt: hij heeft grondige kennis van Ruby en Rails, gecombineerd met grondige kennis van Linux, gecombineerd met een gevoel voor grafisch ontwerp.</em>
                    </p>

                    <p>Marco Jansen, Catawiki, 2011</p>
                    </section>
                    <section id="three">
                        <h2>Get In Touch</h2>
                        <p>Let me help you create wonderful things</p>
                        <div className="row">
                            <div className="8u 12u$(small)">
                                <form method="post" action="https://getform.io/f/b271041b-e50a-4fe1-be23-8e16b705d3d0">
                                    <div className="row uniform 50%">
                                        <div className="6u 12u$(xsmall)"><input type="text" name="name" id="name" placeholder="Name" /></div>
                                        <div className="6u 12u$(xsmall)"><input type="email" name="email" id="email" placeholder="Email" /></div>
                                        <div className="12u"><textarea name="message" id="message" placeholder="Message" rows="4"></textarea></div>
                                    </div>
                                <ul className="actions">
                                    <li><input type="submit" value="Send Message" /></li>
                                </ul>
                                </form>

                            </div>
                            <div className="4u 12u$(small)">
                                <ul className="labeled-icons">
                                    <li>
                                        <h3 className="icon fa-home"><span className="label">Address</span></h3>
                                        Kerklaan 30.<br />
                                        9751NN, Haren<br />
                                        Netherlands
                                    </li>
                                    <li>
                                        <h3 className="icon fa-mobile"><span className="label">Phone</span></h3>
                                        06 144 930 11
                                    </li>
                                    <li>
                                        <h3 className="icon fa-envelope-o"><span className="label">Email</span></h3>
                                        <a href="#">ik@kozy.nl</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                </div>

            </Layout>
        )
    }
}

export default HomeIndex
