
import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/me.jpg'
import larger from '../assets/images/me-large.jpg'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href={larger} className="image avatar"><img src={avatar} alt="" /></a>
                    <h1><strong>Koos Beens</strong>, site reliability<br />
                    Haren</h1>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
